import InfoIcon from "@mui/icons-material/Info";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { PlateBarcodeField } from "features/WorklistTools/shared/components/PlateBarcodeField";
import { StampPlate } from "features/WorklistTools/shared/components/StampPlate";
import {
  PoolingNormalizationStep,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import { SelectableGroup } from "react-selectable-fast";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components";
import { containsCrossContamination } from "../PoolingNormalizationToolHelpers";
import { PoolingNormalizationToolActions } from "../state";
import { useHandleMethodSettingsUpdated } from "../state/handlers";
import { usePoolingNormalizationStateProvider } from "../state/StateProvider";
import { selectStampWells } from "./handlers/SelectStampWells";

const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%;
`;

const StyledPlateSettingsContainer = styled.div`
  margin-top: 10px;
  > * {
    margin: 5px 0;
  }
`;

export const StampPlatesContainer = (intPlateTypeInfo: any) => {
  const { poolingNormalizationToolInternalState } =
    usePoolingNormalizationStateProvider();
  const dispatch = useAppReduxDispatch();
  const updateMethodSettings = useHandleMethodSettingsUpdated();
  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );
  const intPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present.intPlateInfo
  );

  const disableEditStampShape =
    worklistValues.int1StampTopLeft.length > 0 ||
    worklistValues.int2StampTopLeft.length > 0 ||
    worklistValues.int3StampTopLeft.length > 0;

  const handleStampSelection = (stampSelection: any[]) => {
    if (disableEditStampShape) {
      Alert({
        type: AlertType.ERROR,
        message:
          "Stamp Plate can't be updated. You already stamped this pattern to a Destination Plate",
      });
      return;
    }

    selectStampWells(
      dispatch,
      worklistValues.referenceStampShape,
      stampSelection
    );
  };

  const handleStampPlateInfoChanged = (value: any) => {
    updateMethodSettings("stampVolume", value);
  };

  const handleStampReuseTipsChanged = (value: string | undefined) => {
    const reuseTips = value === undefined ? undefined : JSON.parse(value);
    updateMethodSettings("stampReuseTips", reuseTips);
    if (value && containsCrossContamination(worklistValues)) {
      Alert({
        type: AlertType.WARNING,
        message:
          "This worklist mapping contains a cross contamination because it has stamps from multiple intermediate wells",
      });
    }
  };

  const handleDoubleClick = (wellId: string) => {
    if (disableEditStampShape) {
      Alert({
        type: AlertType.ERROR,
        message:
          "Stamp Plate can't be updated. You already stamped this pattern to a Destination Plate",
      });
      return;
    }
    dispatch(
      PoolingNormalizationToolActions.REMOVE_STAMP_WELL({
        referenceStampShape: worklistValues.referenceStampShape.filter(
          (stampWells) => stampWells !== wellId
        ),
      })
    );
  };

  const getErrorMessage = () => {
    const stampVolumeIsZero =
      poolingNormalizationToolInternalState.step ===
        PoolingNormalizationStep.SelectStampMapping &&
      worklistValues.referenceStampShape.length > 0 &&
      poolingNormalizationToolInternalState.methodSettings.stampVolume === 0;
    if (stampVolumeIsZero) {
      return "Stamp Volume cannot be 0";
    } else if (
      poolingNormalizationToolInternalState.methodSettings.stampVolume > 250
    ) {
      return "Must be less than 250 µL";
    }

    return "";
  };

  const isStampReuseTipsRequired =
    worklistValues.referenceStampShape.length > 0 &&
    poolingNormalizationToolInternalState.methodSettings.stampReuseTips ===
      undefined;

  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: "50%",
        overflow: "auto",
      }}
    >
      <div style={{ padding: "20px" }}>
        <PlateBarcodeField
          index={0}
          label={"Stamp Plate"}
          plateBarcode={intPlateInfo[0].plateBarcode}
          handlePlateBarcodeChange={handleStampPlateInfoChanged}
          ignoreError={
            poolingNormalizationToolInternalState.uploadedIntermediatePlate
          }
        />
        <StyledSelectableGroup
          onSelectionFinish={handleStampSelection}
          resetOnStart={true}
        >
          <StampPlate
            worklistTool={WorklistTools.PoolingNormalization}
            plateRows={[1, 2, 3, 4, 5, 6, 7, 8]}
            plateCols={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            selectedWells={worklistValues.referenceStampShape ?? []}
            handleDoubleClick={handleDoubleClick}
          />
        </StyledSelectableGroup>
        <StyledPlateSettingsContainer>
          <NumberField
            style={{ width: "100%", marginTop: "10px" }}
            id="stamp-vol"
            label="Stamp Vol (µL)"
            value={
              poolingNormalizationToolInternalState.methodSettings.stampVolume
            }
            onChange={(value) => handleStampPlateInfoChanged(parseInt(value))}
            hasError={getErrorMessage()?.length > 0}
            errorMessage={getErrorMessage()}
          />
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <FormControl
              sx={{ flexGrow: 1 }}
              variant="outlined"
              error={isStampReuseTipsRequired}
            >
              <InputLabel id="stamp-reuse-tips-label">
                Stamp Reuse Tips
              </InputLabel>
              <Select
                labelId="stamp-reuse-tips-label"
                id="stamp-reuse-tips-select"
                value={
                  poolingNormalizationToolInternalState.methodSettings
                    .stampReuseTips
                }
                label="Stamp Reuse Tips"
                onChange={(e) =>
                  handleStampReuseTipsChanged(
                    e.target.value as string | undefined
                  )
                }
              >
                <MenuItem value={undefined}> </MenuItem>
                <MenuItem value={"false"}>Don't Reuse Tips</MenuItem>
                <MenuItem value={"true"}>Reuse Tips</MenuItem>
              </Select>
              {isStampReuseTipsRequired ? (
                <FormHelperText>Required</FormHelperText>
              ) : null}
            </FormControl>
            <Tooltip
              sx={{ marginLeft: "5px" }}
              title="Tips are recycled across destination plates but changed between batches"
            >
              <InfoIcon />
            </Tooltip>
          </div>
        </StyledPlateSettingsContainer>
      </div>
    </div>
  );
};
