import React from "react";
import { AddRackOptions } from "../../constants";
import { DateSelector } from "../selectors/DateSelector";
import { FieldSixOptionalText } from "../selectors/FieldSixOptionalText";
import { IncludeInactiveWellsCheckbox } from "../selectors/IncludeInactiveWellsCheckbox";
import { LotNumberSelector } from "../selectors/LotNumberSelector";
import { NumberOfDuplicatesSelector } from "../selectors/NumberOfDuplicatesSelector";
import { PassageNumberSelector } from "../selectors/PassageNumberSelector";
import { PlateSelector } from "../selectors/PlateSelector";
import { ResuspensionVolSelector } from "../selectors/ResuspensionVolSelector";

interface ManualPlateProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ManualPlate = ({ values, setFieldValue }: ManualPlateProps) => {
  return (
    <React.Fragment>
      <PlateSelector values={values} setFieldValue={setFieldValue} />
      <NumberOfDuplicatesSelector
        numberOfDuplicates={[1, 2, 3, 4, 5, 6]}
        values={values}
        setFieldValue={setFieldValue}
      />
      <PassageNumberSelector values={values} setFieldValue={setFieldValue} />
      <LotNumberSelector values={values} setFieldValue={setFieldValue} />
      <ResuspensionVolSelector
        values={values}
        setFieldValue={setFieldValue}
        isDisabled={false}
      />
      <FieldSixOptionalText values={values} setFieldValue={setFieldValue} />
      <DateSelector values={values} setFieldValue={setFieldValue} />
      <IncludeInactiveWellsCheckbox
        values={values}
        setFieldValue={setFieldValue}
      />
    </React.Fragment>
  );
};
