import InfoIcon from "@mui/icons-material/Info";
import { FormControl, FormControlLabel, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DestPlateStringKeys,
  Twelve24TCToolActions,
} from "features/WorklistTools/1224TC/state";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import { prvUploadCond } from "../../../plates/handlers/uploadPlateButtonDisabled";
import { MethodSettingsProps } from "../shared";
import { PelletResuspensionReagent } from "./PelletResuspensionReagent";
const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export const PelletResuspensionVolume = ({
  twelve24TCInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  const prvBtwn1And50: boolean = prvUploadCond(twelve24TCInternalState)[1];
  const prvOutside0Or500: boolean = prvUploadCond(twelve24TCInternalState)[2];
  const prvEmpty: boolean = prvUploadCond(twelve24TCInternalState)[3];
  const prvRGTApper: boolean =
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! > 49 &&
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! < 501;
  const classes = useStyles();
  const pelletResuspensionVol =
    twelve24TCInternalState.methodSettings.pelletResuspensionVol;

  const dispatch = useAppReduxDispatch();
  const handleDestPlateSettingsChanged = (
    index: number,
    key: DestPlateStringKeys,
    value: string | number | boolean
  ) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DESTINATION_PLATE_INFO({
        key,
        value,
      })
    );
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          sx={{
            width: "100%",
            marginLeft: 0,
          }}
          classes={{
            label: classes.fullWidth,
          }}
          control={<></>}
          label={
            <FormControl
              variant="outlined"
              fullWidth
              error={prvEmpty || prvBtwn1And50 || prvOutside0Or500}
            >
              <NumberField
                size="small"
                id="pellet-resuspension-vol"
                label={"Pellet Resuspension Vol (µL)"}
                value={pelletResuspensionVol}
                onChange={(value) => {
                  const parsedValue = value === "" ? null : parseInt(value);
                  handleMethodSettingsChanged(
                    "pelletResuspensionVol",
                    parsedValue
                  );
                  handleMethodSettingsChanged(
                    "pelletResuspensionRGT",
                    value === "0"
                      ? 0
                      : twelve24TCInternalState.methodSettings
                          .pelletResuspensionRGT
                  );
                  if (parsedValue === 0) {
                    handleDestPlateSettingsChanged(0, "topupVol", 0);
                  }
                }}
                minValue={0}
                maxValue={500}
                hasError={prvEmpty || prvBtwn1And50 || prvOutside0Or500}
                errorMessage={
                  prvEmpty
                    ? "Enter Pellet Resuspension Volume To Upload Plates"
                    : prvBtwn1And50
                    ? "Pellet Resuspension Volume can't be in the range of [1,49]"
                    : prvOutside0Or500
                    ? "Pellet Resuspension Volume can't be below 0 or above 500"
                    : ""
                }
              />
            </FormControl>
          }
        />
        <Tooltip
          title={
            twelve24TCInternalState.methodSettings!.pelletResuspensionVol == 0
              ? "Pellet will be left with minimal liquid as no resuspension has been requested." //"YES ASPIRATION"
              : "Pellet will be resuspended at selected volume unless the inputted value is higher than 500 or lower than 0." //"NO ASPIRATION"
          }
        >
          <InfoIcon sx={{ alignSelf: "flex-start", marginTop: 1 }} />
        </Tooltip>
      </div>
      {prvRGTApper ? (
        <PelletResuspensionReagent
          twelve24TCInternalState={twelve24TCInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />
      ) : null}
    </>
  );
};
