import { TextField } from "@mui/material";
import { AddRackOptions } from "features/TubeLabeller/constants";

interface FieldSixOptionalTextProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const FieldSixOptionalText = ({
  values,
  setFieldValue,
}: FieldSixOptionalTextProps) => {
  return (
    <TextField
      label="Optional Text (Field 6)"
      value={values.fieldSixOptionalText}
      onChange={(e) => setFieldValue("fieldSixOptionalText", e.target.value)}
    />
  );
};
