import { makeStyles } from "@mui/styles";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import {
  DestPlateInfoState,
  DestPlateStringKeys,
  Twelve24TCToolActions,
} from "../../state";

interface TopupDestProps {
  destPlateInfo: DestPlateInfoState;
  pelletResuspensionVol: number | null;
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export const TopUp = ({
  destPlateInfo,
  pelletResuspensionVol,
}: TopupDestProps) => {
  const classes = useStyles();
  const dispatch = useAppReduxDispatch();
  const handleDestPlateSettingsChanged = (
    index: number,
    key: DestPlateStringKeys,
    value: string | number | boolean
  ) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DESTINATION_PLATE_INFO({
        key,
        value,
      })
    );
  };
  const getErrorMessage = () => {
    if (pelletResuspensionVol !== 0 && destPlateInfo.topupVol === 0) {
      return "Top Up Volume can't be 0";
    }
    if (isNaN(destPlateInfo.topupVol)) {
      return "Top Up Volume can't be empty";
    }
    return "";
  };

  return (
    <>
      <NumberField
        size="small"
        id="top-up-vol"
        style={{ width: "100%", marginTop: 15 }}
        label={"Target Final Tube Volume (Topup)"}
        value={destPlateInfo.topupVol}
        onChange={(value) => {
          handleDestPlateSettingsChanged(0, "topupVol", parseInt(value));
        }}
        minValue={0}
        maxValue={900} //Can't be hardcoded
        hasError={!!getErrorMessage()}
        errorMessage={getErrorMessage()}
        disabled={pelletResuspensionVol === 0}
      />
    </>
  );
};
