import PoolIcon from "@mui/icons-material/Pool";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import TabUnselectedIcon from "@mui/icons-material/TabUnselected";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import WavesIcon from "@mui/icons-material/Waves";
import { Divider, ToggleButton, Tooltip } from "@mui/material";
import { StyledToggleButtonGroup } from "features/WorklistTools/shared/components/styles/styled-components";
import { SelectionProcess } from "features/WorklistTools/shared/interfaces";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import React from "react";
import { PoolingNormalizationToolInternalAction } from "../state/action";
import { usePoolingNormalizationStateProvider } from "../state/StateProvider";

export const SelectionView = () => {
  const {
    poolingNormalizationToolInternalState,
    poolingNormalizationToolDispatch,
  } = usePoolingNormalizationStateProvider();
  const dispatch = useAppReduxDispatch();

  return (
    <React.Fragment>
      <StyledToggleButtonGroup
        value={poolingNormalizationToolInternalState.destSelectionProcess}
        exclusive
        onChange={(e, value) => {
          poolingNormalizationToolDispatch({
            type: PoolingNormalizationToolInternalAction.SET_DEST_SELECTION_PROCESS,
            payload: {
              destSelectionProcess: value,
            },
          });
        }}
      >
        <ToggleButton value={SelectionProcess.Stamp}>
          <Tooltip title="Stamp" placement="right">
            <TabUnselectedIcon style={{ transform: "scaleX(-1)" }} />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={SelectionProcess.RowWise}>
          <Tooltip title="Row" placement="right">
            <ViewColumnIcon style={{ transform: "rotate(90deg)" }} />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={SelectionProcess.ColWise}>
          <Tooltip title="Column" placement="right">
            <ViewColumnIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={SelectionProcess.Pool}>
          <Tooltip title="Pool" placement="right">
            <WavesIcon />
          </Tooltip>
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "5px" }}
      >
        <ToggleButton
          sx={{ border: "none", marginRight: "3px" }}
          value="randomizeWells"
          selected={poolingNormalizationToolInternalState.randomizeWells}
          onChange={() => {
            poolingNormalizationToolDispatch({
              type: PoolingNormalizationToolInternalAction.SET_RANDOMIZE_WELLS,
              payload: {
                randomizeWells:
                  !poolingNormalizationToolInternalState.randomizeWells,
              },
            });
          }}
        >
          <Tooltip title="Randomize" placement="right">
            <ShuffleIcon />
          </Tooltip>
        </ToggleButton>

        <ToggleButton
          sx={{ border: "none", marginRight: "3px" }}
          value="randomizeWells"
          selected={poolingNormalizationToolInternalState.enablePooling}
          onChange={() => {
            poolingNormalizationToolDispatch({
              type: PoolingNormalizationToolInternalAction.SET_ENABLE_POOLING,
              payload: {
                enablePooling:
                  !poolingNormalizationToolInternalState.enablePooling,
              },
            });
          }}
        >
          <Tooltip
            title={`${
              poolingNormalizationToolInternalState.enablePooling
                ? "Disable Pooling"
                : "Enable Pooling"
            }`}
            placement="right"
          >
            <PoolIcon />
          </Tooltip>
        </ToggleButton>
      </div>
    </React.Fragment>
  );
};
