import {
  PlatePosition,
  ReactSelectableFast,
  SelectionProcess,
  SelectionType,
  WorklistValues,
} from "features/WorklistTools/shared/interfaces";
import {
  arrangeColWise,
  arrangeRowWise,
  checkForDoubleSelectionWithMultipleDestSelection,
  getWellSelectionType,
} from "features/WorklistTools/shared/WorklistHelpers";
import { Alert, AlertType } from "shared-components/toast";
import { WorklistValuesState } from "../../state";
import { ITwelve24TCToolInternalState } from "../../state/initial-state";
import { addDestinationWellMapping } from "./addDestinationWellMapping";

export const selectDestWells = async (
  twelve24TCInternalState: ITwelve24TCToolInternalState,
  intPlateInfo: any,
  destPlateInfo: any,
  worklistValues: WorklistValuesState,
  dispatch: (action: { type: string; payload: any }) => void,
  selectedWells: ReactSelectableFast[],
  isConfirmed: (
    prompt: string,
    bullets?: string[] | undefined
  ) => Promise<boolean>
) => {
  const destSelection = selectedWells.map((well) => ({
    plateIndex: well.props.plateIndex,
    plateWellId: well.props.plateWellId,
    isSelectable: false,
  }));

  if (destSelection.length === 0) return;

  const selectionType = getWellSelectionType(
    twelve24TCInternalState.selectedSourceWells,
    destSelection
  );
  const sourcePlateIndex =
    twelve24TCInternalState.selectedSourceWells[0]?.plateIndex;
  let wellMapping: WorklistValues[] = [];
  const currentWorklistValues = worklistValues.harvestWells;
  switch (selectionType) {
    case SelectionType.NoSourceWellSelected:
      Alert({
        type: AlertType.ERROR,
        message: "Please select source well(s)",
      });
      break;
    case SelectionType.SelectMultipleSourceAndDestWells:
      Alert({
        type: AlertType.ERROR,
        message: "You can only select 1 Destination Well",
      });
      break;
    case SelectionType.SelectMultipleSourceAndOneDestWell:
      switch (twelve24TCInternalState.destSelectionProcess) {
        case SelectionProcess.RowWise:
          wellMapping = await arrangeRowWise(
            currentWorklistValues,
            twelve24TCInternalState.selectedSourceWells,
            destSelection[0],
            destPlateInfo.rows,
            destPlateInfo.cols,
            PlatePosition.Destination,
            false
          );
          if (wellMapping.length) {
            addDestinationWellMapping(
              dispatch,
              sourcePlateIndex,
              wellMapping,
              [destPlateInfo],
              worklistValues,
              destSelection,
              false,
              twelve24TCInternalState.methodSettings
            );
          } else {
            Alert({
              type: AlertType.ERROR,
              message: "Selection doesn't fit plate",
            });
          }
          break;
        case SelectionProcess.ColWise:
          wellMapping = await arrangeColWise(
            currentWorklistValues,
            twelve24TCInternalState.selectedSourceWells,
            destSelection[0],
            destPlateInfo.rows,
            destPlateInfo.cols,
            PlatePosition.Destination,
            false
          );
          if (wellMapping.length) {
            addDestinationWellMapping(
              dispatch,
              sourcePlateIndex,
              wellMapping,
              [destPlateInfo],
              worklistValues,
              destSelection,
              false,
              twelve24TCInternalState.methodSettings
            );
          } else {
            Alert({
              type: AlertType.ERROR,
              message: "Selection doesn't fit plate",
            });
          }
          break;
      }
      break;
    case SelectionType.SelectOneSourceAndOneDestWell:
      {
        const error = checkForDoubleSelectionWithMultipleDestSelection(
          currentWorklistValues,
          [
            {
              plateIndex: destSelection[0].plateIndex,
              plateWellId: destSelection[0].plateWellId,
              isSelectable: true,
            },
          ]
        );
        if (error) {
          Alert({
            type: AlertType.ERROR,
            message: "Cannot seed same destination well twice",
          });
          break;
        }
        if (twelve24TCInternalState.selectedSourceWells[0].isSelectable) {
          addDestinationWellMapping(
            dispatch,
            sourcePlateIndex,
            [
              {
                sourcePlateIndex: sourcePlateIndex,
                sourceWellId:
                  twelve24TCInternalState.selectedSourceWells[0].plateWellId,
                destPlateIndex: destSelection[0].plateIndex,
                destWellId: destSelection[0].plateWellId,
                transferVol: "",
              },
            ],
            [destPlateInfo],
            worklistValues,
            destSelection,
            false,
            twelve24TCInternalState.methodSettings
          );
        }
      }
      break;
    case SelectionType.SelectOneSourceAndMultipleDestWells: {
      const error = checkForDoubleSelectionWithMultipleDestSelection(
        currentWorklistValues,
        [
          {
            plateIndex: destSelection[0].plateIndex,
            plateWellId: destSelection[0].plateWellId,
            isSelectable: true,
          },
        ]
      );
      if (error) {
        Alert({
          type: AlertType.ERROR,
          message: "Cannot seed same destination well twice",
        });
        break;
      }
      for (const row of destSelection) {
        if (twelve24TCInternalState.selectedSourceWells[0].isSelectable) {
          wellMapping.push({
            sourcePlateIndex: sourcePlateIndex,
            sourceWellId:
              twelve24TCInternalState.selectedSourceWells[0].plateWellId,
            destPlateIndex: row.plateIndex,
            destWellId: row.plateWellId,
            transferVol: "",
          });
        }
      }
      if (wellMapping.length > 0) {
        addDestinationWellMapping(
          dispatch,
          sourcePlateIndex,
          wellMapping,
          [destPlateInfo],
          worklistValues,
          destSelection,
          false,
          twelve24TCInternalState.methodSettings
        );
      }
      break;
    }
  }
};
