import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { ToggleButton, Tooltip } from "@mui/material";
import { StyledToggleButtonGroup } from "features/WorklistTools/shared/components/styles/styled-components";
import { SelectionProcess } from "features/WorklistTools/shared/interfaces";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import React from "react";
import { Twelve24TCToolInternalAction } from "../state/action";
import { useTwelve24TCStateProvider } from "../state/StateProvider";

export const SelectionView = () => {
  const { twelve24TCToolInternalState, twelve24TCToolDispatch } =
    useTwelve24TCStateProvider();
  const dispatch = useAppReduxDispatch();

  return (
    <React.Fragment>
      <StyledToggleButtonGroup
        value={twelve24TCToolInternalState.destSelectionProcess}
        exclusive
        onChange={(e, value) => {
          twelve24TCToolDispatch({
            type: Twelve24TCToolInternalAction.SET_DEST_SELECTION_PROCESS,
            payload: {
              destSelectionProcess: value,
            },
          });
        }}
      >
        <ToggleButton value={SelectionProcess.RowWise}>
          <Tooltip title="Row" placement="right">
            <ViewColumnIcon style={{ transform: "rotate(90deg)" }} />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={SelectionProcess.ColWise}>
          <Tooltip title="Column" placement="right">
            <ViewColumnIcon />
          </Tooltip>
        </ToggleButton>
      </StyledToggleButtonGroup>
    </React.Fragment>
  );
};
