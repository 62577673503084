import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, Typography } from "@mui/material";
import { WorklistValues } from "features/WorklistTools/shared/interfaces";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { prvDividedByMaxDestWellDict } from "../Twelve24TCToolHelpers";

const StyledMinimumFinalTubeVolumeDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface MinimumFinalTubeVolumeProps {
  harvestWells: WorklistValues[];
  methodSettings: any;
}

export const MinimumFinalTubeVolume = ({
  harvestWells,
  methodSettings,
}: MinimumFinalTubeVolumeProps) => {
  const [minTubeVolume, setMinTubeVolume] = useState(0);

  const noDestWells = () => {
    if (harvestWells.length === 0) {
      return true;
    }
    return false;
  };

  const destWellDict = useMemo(() => {
    return prvDividedByMaxDestWellDict(harvestWells, methodSettings);
  }, [harvestWells, methodSettings]);

  useEffect(() => {
    if (noDestWells()) {
      return;
    }

    let highestSplit = -1;
    for (const [sourceWellKey, destWellTupleVal] of destWellDict) {
      if (destWellTupleVal[1] > highestSplit) {
        highestSplit = destWellTupleVal[1];
      }
    }

    if (highestSplit !== minTubeVolume) {
      setMinTubeVolume(Math.ceil(highestSplit));
    }
  }, [harvestWells, methodSettings]);

  const LargestDestWellVolComponent = () => {
    return (
      <StyledMinimumFinalTubeVolumeDiv>
        <Typography variant="h6">{`Minimum Final Tube Volume: ${minTubeVolume}µL`}</Typography>
        <Tooltip
          title={
            "Pulled from the destination well with the highest volume after accounting for splits i.e. least-split source well(s)."
          }
        >
          <InfoIcon sx={{ alignSelf: "flex-start", marginTop: "3px" }} />
        </Tooltip>
      </StyledMinimumFinalTubeVolumeDiv>
    );
  };

  return noDestWells() ? null : <LargestDestWellVolComponent />;
};
