import { Button } from "@mui/material";
import { DestinationPlate } from "features/WorklistTools/shared/components/DestinationPlate";
import { PlateBarcodeField } from "features/WorklistTools/shared/components/PlateBarcodeField";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import {
  LabwareTypeInfo,
  PlatePosition,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import { is384W_CCU_Plate } from "features/WorklistTools/shared/PlateHelpers";
import {
  getDestinationPlateBarcode,
  getLargestPlateBarcodeSeries,
  setDestPlateType,
} from "features/WorklistTools/shared/WorklistHelpers";
import { useGetIntermediateDeadAndDestPlateBarcodesMutation } from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import { SelectableGroup } from "react-selectable-fast";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import styled from "styled-components";
import { PelletResuspensionVolume } from "../sidebar/MethodSettings/components/PelletResuspensionVolume";
import { SelectPlateCode } from "../sidebar/MethodSettings/components/SelectPlateCode";
import { DestPlateStringKeys, Twelve24TCToolActions } from "../state";
import { useHandleMethodSettingsUpdated } from "../state/handlers";
import { useTwelve24TCStateProvider } from "../state/StateProvider";
import { selectDestWells } from "./handlers/SelectDestinationWells";
import { TopUp } from "./handlers/TopUp";
import { disableSourcePlateUploadBool } from "./handlers/uploadPlateButtonDisabled";
import { MinimumFinalTubeVolume } from "./MinimumFinalTubeVolume";
const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%;
`;

const StyledPlateSettingsContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  & > * {
    margin-top: 10px;
    width: 100%;
  }
`;

const StyledPlateActions = styled.div`
  width: 100%;
  display: flex;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

export const DestinationPlatesContainer = (destPlateTypeInfo: any) => {
  const { isConfirmed } = useConfirm();
  const { twelve24TCToolInternalState, twelve24TCToolDispatch } =
    useTwelve24TCStateProvider();
  const dispatch = useAppReduxDispatch();
  const [getAssociatedBarcodes] =
    useGetIntermediateDeadAndDestPlateBarcodesMutation();
  const updateMethodSettings = useHandleMethodSettingsUpdated();
  const handleMethodSettingsChanged = async (key: string, value: any) => {
    updateMethodSettings(key, value);
  };
  const sourcePlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.sourcePlateInfo
  );
  const deadPlateType = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.deadPlateType
  );
  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.destPlateInfo
  );

  const worklistValues = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.worklistValues
  );

  const handleDestinationWellSelection = (destSelection: any[]) => {
    selectDestWells(
      twelve24TCToolInternalState,
      sourcePlateInfo,
      destPlateInfo,
      worklistValues,
      dispatch,
      destSelection,
      isConfirmed
    );
  };

  const disableUploadButtonDest = (plateIndex: number) => {
    return (
      (plateIndex > 0 && destPlateInfo.rows.length === 0) ||
      destPlateInfo.plateCode == "" ||
      sourcePlateInfo[0].labwareTypeCode === ""
    );
  };

  const handlePlateTypeChange = (
    index: number,
    plateTypeInfo: LabwareTypeInfo
  ) => {
    plateTypeInfo = setDestPlateType(plateTypeInfo);

    getAssociatedBarcodes({
      variables: {
        sourcePlateBarcode: getLargestPlateBarcodeSeries(sourcePlateInfo),
        destPlateCode: destPlateInfo.plateCode,
        hasMultipleSourcePlateTypes: false,
        hasMultipleDestPlateTypes: false,
        has384DeadPlate: is384W_CCU_Plate(deadPlateType),
      },
    }).then((data: any) => {
      const destPlate = getDestinationPlateBarcode(
        data.data.intermediateDeadAndDestPlateBarcodes.destinationPlateBarcode,
        [destPlateInfo]
      );

      dispatch(
        Twelve24TCToolActions.SET_DESTINATION_PLATE({
          index,
          rows: plateTypeInfo.plateRows,
          cols: plateTypeInfo.plateCols,
          operatingVol: plateTypeInfo.defaultLabwareVolume,
          labwareTypeCode: plateTypeInfo.labwareTypeCode,
          plateBarcode: destPlate,
          minOperatingVol: plateTypeInfo.minLabwareVolume,
          maxOperatingVol: plateTypeInfo.maxLabwareVolume,
          topupVol: destPlateInfo.topupVol,
        })
      );
    });
  };

  const getAndSetBarcodes = (plateCode: string) => {
    getAssociatedBarcodes({
      variables: {
        sourcePlateBarcode: getLargestPlateBarcodeSeries(sourcePlateInfo),
        destPlateCode: plateCode,
        hasMultipleSourcePlateTypes: false,
        hasMultipleDestPlateTypes: false,
        has384DeadPlate: is384W_CCU_Plate(deadPlateType),
      },
    }).then((data: any) => {
      const destPlate = getDestinationPlateBarcode(
        data.data.intermediateDeadAndDestPlateBarcodes.destinationPlateBarcode,
        [destPlateInfo]
      );
      handleDestPlateSettingsChanged(0, "plateBarcode", destPlate);
    });
  };

  const handleDestPlateSettingsChanged = (
    index: number,
    key: DestPlateStringKeys,
    value: string | number | boolean
  ) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DESTINATION_PLATE_INFO({
        key,
        value,
      })
    );
  };

  const handleRemovePlateClick = (index: number) => {
    dispatch(
      Twelve24TCToolActions.CLEAR_DESTINATION_PLATE({
        index,
      })
    );
  };

  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: "600px",
        overflow: "auto",
      }}
    >
      <div style={{ padding: "20px", width: "100%" }}>
        <PlateBarcodeField
          index={0}
          label={"Destination Plate Barcode"}
          plateBarcode={destPlateInfo.plateBarcode}
          handlePlateBarcodeChange={handleDestPlateSettingsChanged}
        />

        <StyledSelectableGroup
          onSelectionFinish={handleDestinationWellSelection}
          resetOnStart={true}
        >
          <DestinationPlate
            worklistTool={WorklistTools.Twelve24TC}
            index={0}
            plateRows={destPlateInfo.rows}
            plateCols={destPlateInfo.cols}
            wellMapping={[...worklistValues.harvestWells] ?? []}
            sourcePlatePosition={PlatePosition.Source}
            sourcePlateInfo={sourcePlateInfo}
          />
        </StyledSelectableGroup>
        <StyledPlateActions>
          <Button
            variant="outlined"
            disabled={destPlateInfo.rows.length < 1}
            onClick={() => handleRemovePlateClick(0)}
          >
            Clear Plate
          </Button>
        </StyledPlateActions>
        <StyledPlateSettingsContainer>
          <PlateType
            platePosition={PlatePosition.Destination}
            isDisabled={
              disableSourcePlateUploadBool(twelve24TCToolInternalState) ||
              disableUploadButtonDest(0)
            }
            acceptedPlateTypes={destPlateTypeInfo.destPlateInfo}
            plateType={destPlateInfo.labwareTypeCode}
            setPlateType={(plateTypeInfo: LabwareTypeInfo) =>
              handlePlateTypeChange(0, plateTypeInfo)
            }
          />
          <SelectPlateCode
            twelve24TCInternalState={twelve24TCToolInternalState}
            handleMethodSettingsChanged={handleDestPlateSettingsChanged}
            getAndSetBarcodes={getAndSetBarcodes}
          />
          <PelletResuspensionVolume
            twelve24TCInternalState={twelve24TCToolInternalState}
            handleMethodSettingsChanged={handleMethodSettingsChanged}
          />
          <MinimumFinalTubeVolume
            harvestWells={worklistValues.harvestWells}
            methodSettings={twelve24TCToolInternalState.methodSettings}
          />
          <TopUp
            destPlateInfo={destPlateInfo}
            pelletResuspensionVol={
              twelve24TCToolInternalState.methodSettings.pelletResuspensionVol
            }
          />
        </StyledPlateSettingsContainer>
      </div>
      <ConfirmDialog />
    </div>
  );
};
