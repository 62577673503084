import { Button } from "@mui/material";
import { IntermediatePlate } from "features/WorklistTools/shared/components/IntermediatePlate";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { PlateBarcodeField } from "features/WorklistTools/shared/components/PlateBarcodeField";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import {
  UploadedSourcePlate,
  UploadSourcePlateDialog,
} from "features/WorklistTools/shared/components/UploadSourcePlateDialog";
import { useCtrlDown } from "features/WorklistTools/shared/hooks/hooks";
import {
  PlatePosition,
  PoolingNormalizationStep,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import {
  GetLabwareTypesQuery,
  useGetExistingDeadPlateLazyQuery,
  useGetPlatesInfoLazyQuery,
} from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useState } from "react";
import { SelectableGroup } from "react-selectable-fast";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components";
import {
  IntermediatePlateInfoState,
  PoolingNormalizationToolActions,
} from "../state";
import { PoolingNormalizationToolInternalAction } from "../state/action";
import { usePoolingNormalizationStateProvider } from "../state/StateProvider";
import { handleUploadPlate } from "./handlers/HandleUploadPlate";
import { selectIntermediateWells } from "./handlers/SelectIntermediateWells";

const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%;
`;

const StyledPlateActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const StyledPlateSettingsContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const IntermediatePlatesContainer = (intPlateTypeInfo: any) => {
  const [uploadIntPlateOpen, setUploadIntPlateOpen] = useState(false);

  const [labwareTypeDialogOpen, setLabwareTypeDialogOpen] = useState(false);
  const {
    poolingNormalizationToolInternalState,
    poolingNormalizationToolDispatch,
  } = usePoolingNormalizationStateProvider();
  const sourcePlateIndex = poolingNormalizationToolInternalState.sourceIntIndex;
  const destinationPlateIndex =
    poolingNormalizationToolInternalState.destinationIntIndex;

  const dispatch = useAppReduxDispatch();
  const sourcePlateInfo = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.sourcePlateInfo
  );
  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );
  const intPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present.intPlateInfo
  );
  const ctrlDown = useCtrlDown();
  const [colorIndex, setColorIndex] = useState(0);
  const [getPlateInfo, { data: plateInfo }] = useGetPlatesInfoLazyQuery({
    onCompleted: (data) => {
      handleUploadPlate({
        data,
        setLabwareTypeDialogOpen,
        uploadIntermediatePlate: true,
        sourcePlateInfo,
        dispatch,
        colorIndex,
        setColorIndex,
        selectedPlateIndex: 0,
      });
      setUploadedIntermediatePlate(true);
    },
  });
  const [getExistingDeadPlate, { data: deadPlate }] =
    useGetExistingDeadPlateLazyQuery({
      onCompleted: (data) => {
        if (!data.existingDeadPlate) {
          Alert({
            type: AlertType.ERROR,
            message: "No Dead Plate Associated with this Intermediate Plate",
          });

          dispatch(PoolingNormalizationToolActions.RESET_STATE());
          return;
        }
        dispatch(
          PoolingNormalizationToolActions.UPDATE_DEAD_PLATEBARCODE({
            value: data.existingDeadPlate?.plateBarcode,
          })
        );

        dispatch(
          PoolingNormalizationToolActions.UPDATE_DEAD_PLATETYPE({
            value: data.existingDeadPlate?.labwareType?.labwareTypeCode,
          })
        );
      },
    });

  const setUploadedIntermediatePlate = (uploadedPlate: boolean) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_UPLOADED_INTERMEDIATE_PLATE,
      payload: {
        uploadedIntermediatePlate: uploadedPlate,
      },
    });
    if (uploadedPlate) return;
    dispatch(
      PoolingNormalizationToolActions.CLEAR_SOURCE_PLATE({
        index: 0,
      })
    );
    dispatch(
      PoolingNormalizationToolActions.CLEAR_INTERMEDIATE_PLATE({
        index: 0,
        key: "plateBarcode",
        value: "",
      })
    );
    dispatch(
      PoolingNormalizationToolActions.SET_INTERMEDIATE_PLATE({
        index: 0,
        operatingVol: 0,
        labwareTypeCode: "",
        rows: [],
        cols: [],
      })
    );
  };

  const handleUploadIntPlateClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    plate: UploadedSourcePlate | undefined,
    data: GetLabwareTypesQuery | undefined,
    int1CountAliquot: number
  ) => {
    setUploadIntPlateOpen(false);
    getPlateInfo({
      variables: {
        where: {
          plateCreatedId: { eq: plate!.plateCreatedId },
          containers: {
            some: {
              isActive: { eq: true },
            },
          },
        },
      },
    });
    if (int1CountAliquot === 0) {
      getExistingDeadPlate({
        variables: {
          srcPlateId: plate!.plateCreatedId,
        },
      });
    }
  };

  const handleIntermediateOneSelection = (int1Selection: any) => {
    selectIntermediateWells(
      poolingNormalizationToolDispatch,
      poolingNormalizationToolInternalState,
      dispatch,
      worklistValues,
      int1Selection,
      intPlateInfo,
      sourcePlateInfo
    );
  };

  const handleIntermediateTwoSelection = (int2Selection: any) => {
    selectIntermediateWells(
      poolingNormalizationToolDispatch,
      poolingNormalizationToolInternalState,
      dispatch,
      worklistValues,
      int2Selection,
      intPlateInfo,
      sourcePlateInfo
    );
  };

  const handleIntermediateThreeSelection = (int3Selection: any) => {
    selectIntermediateWells(
      poolingNormalizationToolDispatch,
      poolingNormalizationToolInternalState,
      dispatch,
      worklistValues,
      int3Selection,
      intPlateInfo,
      sourcePlateInfo
    );
  };

  const handlePlateTypeChanged = (index: number, plateTypeInfo: any) => {
    if (plateTypeInfo.labwareTypeCode === "EK_24_Vb_L_2053") {
      Alert({
        type: AlertType.WARNING,
        message:
          "WARNING: 24W Intermediate blocks haven't been thoroughly tested for this method",
      });
    }
    dispatch(
      PoolingNormalizationToolActions.SET_INTERMEDIATE_PLATE({
        index,
        rows: plateTypeInfo.plateRows,
        cols: plateTypeInfo.plateCols,
        operatingVol: plateTypeInfo.defaultLabwareVolume,
        labwareTypeCode: plateTypeInfo.labwareTypeCode,
      })
    );
  };

  const handleIntPlateInfoChanged = (
    index: number,
    key: string,
    value: any
  ) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_INT_PLATE_INFO({
        index,
        key,
        value,
      })
    );
  };

  const handleDoubleClick = (index: number) => {
    if (sourcePlateIndex === index) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_SOURCE_INT_INDEX,
        payload: {
          sourceIntIndex: undefined,
        },
      });
    } else if (destinationPlateIndex === index) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_DESTINATION_INT_INDEX,
        payload: {
          destinationIntIndex: undefined,
        },
      });
    } else if (sourcePlateIndex === undefined) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_SOURCE_INT_INDEX,
        payload: {
          sourceIntIndex: index,
        },
      });
    } else if (destinationPlateIndex === undefined) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_DESTINATION_INT_INDEX,
        payload: {
          destinationIntIndex: index,
        },
      });
    }
  };

  const handleWellHover = (wellId: string) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_HOVER_WELL_ID,
      payload: {
        hoverWellId: wellId,
      },
    });
  };

  const handleMethodSettingsChanged = (key: string, value: string | number) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.UPDATE_METHOD_SETTINGS,
      payload: {
        key,
        value,
      },
    });
  };

  const disableUploadButton = () => {
    return (
      poolingNormalizationToolInternalState.uploadedIntermediatePlate ||
      poolingNormalizationToolInternalState.methodSettings!.selectedSystem === 0
    );
  };
  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: "600px",
        // width: "50%",
        overflow: "auto",
      }}
    >
      {intPlateInfo.map((item: IntermediatePlateInfoState, index: number) => (
        <div style={{ padding: "20px" }}>
          <PlateBarcodeField
            index={index}
            label={`Intermediate Plate Barcode ${index + 1}`}
            plateBarcode={item.plateBarcode}
            handlePlateBarcodeChange={handleIntPlateInfoChanged}
            disabled={item.plateBarcode === ""}
            ignoreError={
              poolingNormalizationToolInternalState.uploadedIntermediatePlate
            }
          />
          <div onDoubleClick={() => handleDoubleClick(index)}>
            <StyledSelectableGroup
              onSelectionFinish={
                index === 0
                  ? handleIntermediateOneSelection
                  : index === 1
                  ? handleIntermediateTwoSelection
                  : handleIntermediateThreeSelection
              }
              resetOnStart={
                ctrlDown.worklistToolInternalState.setCtrlDown ? false : true
              }
              allowCtrlClick={true}
            >
              <IntermediatePlate
                worklistTool={WorklistTools.PoolingNormalization}
                index={index}
                plateRows={item.rows}
                plateCols={item.cols}
                wellMapping={
                  index === 0
                    ? worklistValues.harvestWells
                    : index === 1
                    ? worklistValues.int1ToInt2
                    : [
                        ...worklistValues.int2ToInt3,
                        ...worklistValues.int1ToInt3,
                      ]
                }
                borderColor={
                  poolingNormalizationToolInternalState.step ===
                    PoolingNormalizationStep.SelectSourceToIntermediate &&
                  sourcePlateIndex === index
                    ? "green"
                    : poolingNormalizationToolInternalState.step ===
                        PoolingNormalizationStep.SelectSourceToIntermediate &&
                      destinationPlateIndex === index
                    ? "red"
                    : ""
                }
                sourcePlateInfo={sourcePlateInfo}
                sourcePlatePosition={
                  index === 0
                    ? PlatePosition.Source
                    : PlatePosition.Intermediate
                }
                handleWellHover={handleWellHover}
              />
            </StyledSelectableGroup>
          </div>

          {poolingNormalizationToolInternalState.uploadedIntermediatePlate ||
          (item.labwareTypeCode === "" &&
            sourcePlateInfo[0].labwareTypeCode === "") ? (
            <StyledPlateActions>
              <Button
                variant="outlined"
                onClick={() => setUploadIntPlateOpen(true)}
                disabled={disableUploadButton()}
              >
                Upload Plate
              </Button>
              <Button
                variant="outlined"
                disabled={item.rows.length < 1}
                onClick={() => setUploadedIntermediatePlate(false)}
              >
                Remove Plate
              </Button>
            </StyledPlateActions>
          ) : null}

          <StyledPlateSettingsContainer>
            <PlateType
              platePosition={PlatePosition.Intermediate}
              isDisabled={
                poolingNormalizationToolInternalState.methodSettings!
                  .selectedSystem === 0
              }
              acceptedPlateTypes={intPlateTypeInfo.intPlateInfo}
              plateType={item.labwareTypeCode}
              setPlateType={(plateTypeInfo) =>
                handlePlateTypeChanged(index, plateTypeInfo)
              }
            />
            <div style={{ display: "flex", width: "100%" }}>
              <NumberField
                style={{ width: "50%", marginTop: "10px", marginRight: "5px" }}
                id="operating-vol"
                label="Operating Vol (µL)"
                value={item.operatingVol}
                onChange={(value) =>
                  handleIntPlateInfoChanged(
                    index,
                    "operatingVol",
                    parseInt(value)
                  )
                }
              />

              <NumberField
                style={{ width: "50%", marginTop: "10px" }}
                id="resuspension-vol"
                label="Resuspension Vol (µL)"
                value={item.resuspensionVol}
                onChange={(value) =>
                  handleIntPlateInfoChanged(
                    index,
                    "resuspensionVol",
                    parseInt(value)
                  )
                }
                hasError={
                  item.labwareTypeCode !== "" && item.resuspensionVol <= 0
                }
                errorMessage="Required"
              />
            </div>
          </StyledPlateSettingsContainer>
        </div>
      ))}
      <UploadSourcePlateDialog
        isOpen={uploadIntPlateOpen}
        handleClose={() => setUploadIntPlateOpen(false)}
        handleUpload={handleUploadIntPlateClose}
        queryVariables={
          intPlateTypeInfo.intPlateInfo
            ? {
                where: {
                  labwareTypeId: {
                    in: intPlateTypeInfo.intPlateInfo.map((e: any) => {
                      return e.labwareTypeId;
                    }),
                  },
                },
              }
            : {}
        }
        title="Select an Intermediate Plate to Upload"
        isUploadingIntermediate={true}
        methodSettings={poolingNormalizationToolInternalState.methodSettings}
        updateMethodSettings={(key, value) =>
          handleMethodSettingsChanged(key, value)
        }
      ></UploadSourcePlateDialog>
    </div>
  );
};
