import { Worklist } from "features/WorklistTools/shared/interfaces";
import { useAppReduxSelector } from "hooks/reduxHooks";
import {
  buildAuxPlateBarcodeWorklist,
  buildHarvestWellsWorklist,
  buildInt1StampTopLeftWorklist,
  buildInt1ToDestWorklist,
  buildInt1ToInt2Worklist,
  buildInt1ToInt3Worklist,
  buildInt2StampTopLeftWorklist,
  buildInt2ToDestWorklist,
  buildInt2ToInt3Worklist,
  buildInt3StampTopLeftWorklist,
  buildInt3ToDestWorklist,
  buildMappingFileWorklist,
  buildMethodSettingsWorklist,
  buildOperatingVolumeWorklist,
  buildReferenceStampShape,
  buildSeedingPriorityWorklist,
} from "../PoolingNormalizationToolHelpers";
import { PoolingNormalizationToolInternalAction } from "./action";
import { StampSingleChannelPriority } from "./initial-state";
import { usePoolingNormalizationStateProvider } from "./StateProvider";

export const useHandleMethodSettingsUpdated = () => {
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  return (key: string, value: any) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.UPDATE_METHOD_SETTINGS,
      payload: {
        key,
        value,
      },
    });
  };
};

// export const useHandleMethodSettingsUpdatedByTemplate = () => {
//   const { poolingNormalizationToolDispatch } =
//     usePoolingNormalizationStateProvider();
//   return (methodSettings: any) => {
//     poolingNormalizationToolDispatch({
//       type: PoolingNormalizationToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
//       payload: {
//         dissociationTime: methodSettings.dissociationTime,
//         spinParamPercent: methodSettings.spinParamPercent,
//         spinTime: methodSettings.spinTime,
//         spinAccel: methodSettings.spinAccel,
//         spinDecel: methodSettings.spinDecel,
//         dissociationWashRGT: methodSettings.dissociationWashRGT,
//         reFeedWellsRGT: methodSettings.reFeedWellsRGT,
//         harvestWashRGT: methodSettings.harvestWashRGT,
//         extraPelletWash: methodSettings.extraPelletWash,
//         topUpDestPlates: methodSettings.topUpDestPlates,
//         dissociation: methodSettings.dissociation,
//         washBeforeDissociation: methodSettings.washBeforeDissociation,
//         washAfterDissociation: methodSettings.washAfterDissociation,
//         preprocessPlate: methodSettings.preprocessPlate,
//         reFeedSourceWells: methodSettings.reFeedSourceWells,
//         harvestWash: methodSettings.harvestWash,
//         stampRows: methodSettings.stampRows,
//         stampColumns: methodSettings.stampColumns,
//         stampVolume: methodSettings.stampVolume,
//       },
//     });
//   };
// };

export const useSetRequiredWarnings = () => {
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  return (value: string[]) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_REQUIRED_WARNINGS,
      payload: {
        requiredWarnings: value,
      },
    });
  };
};

export const useSetOptionalWarnings = () => {
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  return (value: string[]) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_OPTIONAL_WARNINGS,
      payload: {
        optionalWarnings: value,
      },
    });
  };
};

export const useSetWorklist = () => {
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  return (worklist: any[]) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_WORKLIST,
      payload: {
        worklist: worklist,
        worklistPreviewOpen: true,
      },
    });
  };
};

export const useSetWorkistPreviewOpen = () => {
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  return (worklistPreviewOpen: boolean) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_WORKLIST_PREVIEW_OPEN,
      payload: {
        worklistPreviewOpen: worklistPreviewOpen,
      },
    });
  };
};

export const useGenerateWorklistClick = () => {
  const {
    poolingNormalizationToolInternalState,
    poolingNormalizationToolDispatch,
  } = usePoolingNormalizationStateProvider();
  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );
  const sourcePlateInfo = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.sourcePlateInfo
  );
  const destPlateInfo = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.destPlateInfo
  );
  const intPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present.intPlateInfo
  );

  const deadPlateBarcode = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.deadPlateBarcode
  );

  const deadPlateType = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.deadPlateType
  );

  //Exclude harvest wells if intermediate plate was uploaded
  const harvestWells =
    poolingNormalizationToolInternalState.uploadedIntermediatePlate
      ? []
      : buildHarvestWellsWorklist(
          worklistValues.harvestWells,
          sourcePlateInfo,
          intPlateInfo,
          poolingNormalizationToolInternalState.methodSettings
        );

  const int1ToInt2 = buildInt1ToInt2Worklist(
    worklistValues.int1ToInt2,
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo,
    poolingNormalizationToolInternalState.methodSettings
  );
  const int1ToInt3 = buildInt1ToInt3Worklist(
    worklistValues.int1ToInt3,
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo,
    poolingNormalizationToolInternalState.methodSettings
  );
  const int2ToInt3 = buildInt2ToInt3Worklist(
    worklistValues.int2ToInt3,
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo,
    poolingNormalizationToolInternalState.methodSettings
  );

  const int1StampTopLefts = buildInt1StampTopLeftWorklist(
    worklistValues.int1StampTopLeft,
    intPlateInfo,
    destPlateInfo
  );
  const int2StampTopLefts = buildInt2StampTopLeftWorklist(
    worklistValues.int2StampTopLeft,
    intPlateInfo,
    destPlateInfo
  );
  const int3StampTopLefts = buildInt3StampTopLeftWorklist(
    worklistValues.int3StampTopLeft,
    intPlateInfo,
    destPlateInfo
  );

  const int1ToDest = buildInt1ToDestWorklist(
    worklistValues.int1ToDest,
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo,
    poolingNormalizationToolInternalState.methodSettings
  );

  const int2ToDest = buildInt2ToDestWorklist(
    worklistValues.int2ToDest,
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo,
    poolingNormalizationToolInternalState.methodSettings
  );

  const int3ToDest = buildInt3ToDestWorklist(
    worklistValues.int3ToDest,
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo,
    poolingNormalizationToolInternalState.methodSettings
  );

  let prioritySeeding = [];
  if (
    poolingNormalizationToolInternalState.stampSingleChannelPriority ===
    StampSingleChannelPriority.SingleChannelFirst
  ) {
    prioritySeeding = [
      ...int1ToDest,
      ...int2ToDest,
      ...int3ToDest,
      ...int1StampTopLefts,
      ...int2StampTopLefts,
      ...int3StampTopLefts,
    ];
  } else {
    prioritySeeding = [
      ...int1StampTopLefts,
      ...int2StampTopLefts,
      ...int3StampTopLefts,
      ...int1ToDest,
      ...int2ToDest,
      ...int3ToDest,
    ];
  }

  const referenceStampShape = buildReferenceStampShape(
    worklistValues.referenceStampShape
  );

  const labwareOperatingVolumes = buildOperatingVolumeWorklist(
    sourcePlateInfo,
    destPlateInfo
  );

  const seedingPriority = buildSeedingPriorityWorklist(
    poolingNormalizationToolInternalState.methodSettings.groupedSeedingPriority,
    poolingNormalizationToolInternalState.methodSettings
      .seedingPriority_12_24_Well,
    poolingNormalizationToolInternalState.methodSettings
      .seedingPriority_96_384_Well
  );

  const methodSettingsWorklist = buildMethodSettingsWorklist(
    poolingNormalizationToolInternalState,
    true
  );

  const auxPlateBarcodes = buildAuxPlateBarcodeWorklist(
    intPlateInfo,
    deadPlateBarcode,
    deadPlateType,
    poolingNormalizationToolInternalState.methodSettings
  );

  const mappingFileKeywords = buildMappingFileWorklist(
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo
  );

  let worklist: Worklist[] = [];

  worklist = [
    ...harvestWells,
    ...int1ToInt2,
    ...int1ToInt3,
    ...int2ToInt3,
    ...prioritySeeding,
    ...referenceStampShape,
    ...labwareOperatingVolumes,
    ...seedingPriority,
    ...methodSettingsWorklist,
    ...auxPlateBarcodes,
    ...mappingFileKeywords,
  ];

  return () => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_WORKLIST,
      payload: {
        worklist,
        worklistPreviewOpen: true,
      },
    });
  };
};
