import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { Divider, Tooltip } from "@mui/material";
import { BackdropOpen } from "App";
import { Methods } from "enums/Methods";
import { NewTemplateNameDialog } from "features/WorklistTools/shared/components/NewTemplateNameDialog";
import {
  StyledIconButton,
  StyledToolbarSection,
} from "features/WorklistTools/shared/components/styles/styled-components";
import { TwoOptionDialog } from "features/WorklistTools/shared/components/TwoOptionDialog";
import { UploadWorklist } from "features/WorklistTools/shared/components/UploadWorklist";
import { WarningDialog } from "features/WorklistTools/shared/components/WarningDialog";
import { ViewWorklistTemplate } from "features/WorklistTools/shared/components/WorklistTemplates/ViewWorklistTemplates";
import { WorklistToolWarnings } from "features/WorklistTools/shared/components/WorklistToolWarnings";
import { fillSourcePlateWithColors } from "features/WorklistTools/shared/WorklistHelpers";
import {
  useDeleteExistingMethodReservationMutation,
  useGetExistingMethodReservationLazyQuery,
  useOverwriteExistingWorklistTemplateMutation,
  useSaveWorklistToolTemplateMutation,
  WorklistToolTemplate,
} from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect, useState } from "react";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import {
  buildMethodSettingsWorklist,
  buildSeedingPriorityWorklist,
  containsCrossContamination,
} from "../PoolingNormalizationToolHelpers";
import {
  DestPlateInfoState,
  PoolingNormalizationToolActions,
  WorklistValuesState,
} from "../state";
import { PoolingNormalizationToolInternalAction } from "../state/action";
import { useGenerateWorklistClick } from "../state/handlers";
import { usePoolingNormalizationStateProvider } from "../state/StateProvider";
import {
  getPoolingOptionalWorklistWarnings,
  getPoolingRequiredWorklistWarnings,
} from "../warnings";
import {
  getDeadPlateInfo,
  getDestPlateInfo,
  getHarvestWells,
  getIntMapping,
  getIntPlateInfo,
  getIntStampTopLeft,
  getIntToDest,
  getSourcePlateInfo,
  getStampTopLeftTransfers,
  parseMethodSettingsTemplate,
  parseStampTopLeftValues,
  parseWorklistValues,
} from "./handlers/HandleOpenTemplate";
import {
  parseUploadedWorklist,
  UploadedWorklist,
} from "./handlers/HandleUploadWorklist";

export const GeneralOptions = () => {
  const dispatch = useAppReduxDispatch();
  const {
    poolingNormalizationToolInternalState,
    poolingNormalizationToolDispatch,
  } = usePoolingNormalizationStateProvider();
  const methodSettings = poolingNormalizationToolInternalState.methodSettings;

  const [requiredWarnings, setRequiredWarnings] = useState([""]);
  const [optionalWarnings, setOptionalWarnings] = useState([""]);
  const [twoOptionDialogOpen, setTwoOptionDialogOpen] = useState(false);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [uploadWorklistOpen, setUploadWorklistOpen] = useState(false);
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [saveWorklistTemplate] = useSaveWorklistToolTemplateMutation();
  const poolingNormalizationToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present
  );

  const [overwriteExistingWorklistToolTemplate] =
    useOverwriteExistingWorklistTemplateMutation({
      onCompleted() {
        Alert({
          type: AlertType.SUCCESS,
          message: "Template Saved",
        });
      },
    });

  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
      onCompleted() {
        if (!sourcePlateAlreadyReserved) return;
        Alert({
          type: AlertType.WARNING,
          message:
            "Source Plate has already been reserved. You must delete the reservation if you would like to generate a worklist with this plate",
        });
      },
    });

  const [deleteMethodReservation] =
    useDeleteExistingMethodReservationMutation();

  const sourcePlateAlreadyReserved =
    (methodReservation?.existingMethodReservation?.length ?? 0) > 0;

  useEffect(() => {
    getExistingMethodReservation({
      variables: {
        where: {
          isCompleted: { eq: false },
          methodReservationsPlateBarcodes: {
            some: {
              plateBarcode: {
                in: poolingNormalizationToolInfo.sourcePlateInfo.map(
                  (item) => item.plateBarcode
                ),
              },
            },
          },
        },
      },
    });
  }, [poolingNormalizationToolInfo.sourcePlateInfo]);

  const handleDeleteMethodReservationClick = () => {
    deleteMethodReservation({
      variables: {
        methodReservationId: methodReservation?.existingMethodReservation
          ? methodReservation!.existingMethodReservation[0]!.methodReservationId
          : 0,
      },
    }).then((res) => {
      getExistingMethodReservation({
        variables: {
          where: {
            isCompleted: { eq: false },
            methodReservationsPlateBarcodes: {
              some: {
                plateBarcode: {
                  eq: poolingNormalizationToolInfo.sourcePlateInfo[0]
                    .plateBarcode,
                },
              },
            },
          },
        },
      });
    });
  };

  const handleSaveTemplateClose = (
    saveTemplate: boolean,
    templateName: string
  ) => {
    setSaveTemplateDialogOpen(false);
    if (!saveTemplate) return;

    saveWorklistTemplate({
      variables: {
        input: getTemplateInfoToSave(undefined, templateName),
      },
    }).then((data) => {
      Alert({
        type: AlertType.SUCCESS,
        message: "Template Saved",
      });
    });
  };

  const [overwriteTemplateMode, setOverwriteTemplateMode] = useState(false);
  const handleTwoOptionDialogClose = (rightOption: boolean) => {
    setTwoOptionDialogOpen(false);
    if (rightOption) {
      setTemplateDialogOpen(true);
      setOverwriteTemplateMode(true);
      return;
    }
    setSaveTemplateDialogOpen(true);
  };

  const [uploadingWorklist, setUploadingWorklist] = useState(false);
  useEffect(() => {
    BackdropOpen({
      open: uploadingWorklist,
      header: "Uploading Worklist",
      subText: "Do not reload page",
      size: 60,
    });
  }, [uploadingWorklist]);

  const handleUploadWorklistClose = async (
    uploadWorklist: boolean,
    worklistRows: UploadedWorklist[]
  ) => {
    setUploadWorklistOpen(false);
    if (!uploadWorklist) return;
    setUploadingWorklist(true);
    const parsedWorklistInfo = await parseUploadedWorklist(worklistRows);
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_STATE,
      payload: {
        key: "uploadedWorklist",
        value: true,
      },
    });
    console.log("worklistInfo", parsedWorklistInfo);
    dispatch(
      PoolingNormalizationToolActions.UPLOAD_SOURCE_INT_DEAD_AND_DEST_PLATE_INFO(
        {
          sourcePlateInfo: parsedWorklistInfo.sourcePlateInfo,
          intPlateInfo: parsedWorklistInfo.intPlateInfo,
          destPlateInfo: parsedWorklistInfo.destPlateInfo,
          deadPlateBarcode: parsedWorklistInfo.deadPlateBarcode,
          deadPlateType: parsedWorklistInfo.deadPlateType,
        }
      )
    );
    dispatch(
      PoolingNormalizationToolActions.UPLOAD_WORKLIST_VALUES({
        harvestWells: parsedWorklistInfo.harvestWells,
        int1ToInt2: parsedWorklistInfo.int1ToInt2,
        int1ToInt3: parsedWorklistInfo.int1ToInt3,
        int2ToInt3: parsedWorklistInfo.int2ToInt3,
        int1ToDest: parsedWorklistInfo.int1ToDest,
        int2ToDest: parsedWorklistInfo.int2ToDest,
        int3ToDest: parsedWorklistInfo.int3ToDest,
        int1StampTopLeft: parsedWorklistInfo.int1StampTopLeft,
        int2StampTopLeft: parsedWorklistInfo.int2StampTopLeft,
        int3StampTopLeft: parsedWorklistInfo.int3StampTopLeft,
        referenceStampShape: parsedWorklistInfo.referenceStampShape,
        stampTopLeftTransfers: parsedWorklistInfo.stampTopLeftTransfers,
      })
    );

    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
      payload: {
        methodSettings: parsedWorklistInfo.methodSettings,
      },
    });
    setUploadingWorklist(false);
  };

  const checkForWarnings = async () => {
    const requiredWarning = await getPoolingRequiredWorklistWarnings(
      poolingNormalizationToolInfo.sourcePlateInfo,
      poolingNormalizationToolInfo.destPlateInfo,
      poolingNormalizationToolInfo.intPlateInfo,
      poolingNormalizationToolInfo.deadPlateType,
      poolingNormalizationToolInfo.worklistValues,
      poolingNormalizationToolInternalState
    );
    const optionalWarning = await getPoolingOptionalWorklistWarnings(
      poolingNormalizationToolInfo.sourcePlateInfo,
      poolingNormalizationToolInfo.intPlateInfo,
      poolingNormalizationToolInfo.destPlateInfo,
      poolingNormalizationToolInfo.worklistValues,
      methodSettings
    );
    setRequiredWarnings(requiredWarning);
    setOptionalWarnings(optionalWarning);
  };

  const generateWorklist = useGenerateWorklistClick();
  const handleGenerateWorklistClick = () => {
    const warnings = getPoolingOptionalWorklistWarnings(
      poolingNormalizationToolInfo.sourcePlateInfo,
      poolingNormalizationToolInfo.intPlateInfo,
      poolingNormalizationToolInfo.destPlateInfo,
      poolingNormalizationToolInfo.worklistValues,
      methodSettings
    );
    setOptionalWarnings(warnings);
    if (warnings.length > 0) {
      setWarningDialogOpen(true);
    } else if (requiredWarnings.length < 1) {
      generateWorklist();
    }
  };

  const { isConfirmed } = useConfirm();
  const handleWarningDialogClose = async (continueAction: boolean) => {
    setWarningDialogOpen(false);
    if (continueAction && requiredWarnings.length < 1) {
      if (
        !methodSettings.dissociation ||
        (methodSettings.dissociation && !methodSettings.washBeforeDissociation)
      ) {
        let warning = "";
        if (!methodSettings.dissociation) {
          warning =
            "Dissociation is disabled. Are you sure you want to continue?";
        } else if (
          methodSettings.dissociation &&
          !methodSettings.washBeforeDissociation
        ) {
          warning =
            "Dissociation Wash is disabled. Are you sure you want to continue?";
        }
        const confirmed = await isConfirmed(warning);

        if (!confirmed) {
          return;
        }
      }
      generateWorklist();
    }
  };

  const handleOverwriteTemplateClose = (
    overwriteTemplate: boolean,
    templateInfo: WorklistToolTemplate | undefined
  ) => {
    setTemplateDialogOpen(false);
    if (!overwriteTemplate) {
      setOverwriteTemplateMode(false);
      return;
    }

    overwriteExistingWorklistToolTemplate({
      variables: {
        input: getTemplateInfoToSave(templateInfo!.worklistToolTemplateId),
      },
    });

    return;
  };

  const getTemplateInfoToSave = (
    worklistToolTemplateId?: number,
    templateName?: string
  ) => {
    const worklistValues = poolingNormalizationToolInfo.worklistValues;
    const intPlateState = poolingNormalizationToolInfo.intPlateInfo;
    const destPlateState = poolingNormalizationToolInfo.destPlateInfo;

    const harvestWells = getHarvestWells(poolingNormalizationToolInfo);
    const int1ToInt2 = getIntMapping(
      worklistValues.int1ToInt2,
      "Int1ToInt2",
      intPlateState
    );
    const int1ToInt3 = getIntMapping(
      worklistValues.int1ToInt3,
      "Int1ToInt3",
      intPlateState
    );
    const int2ToInt3 = getIntMapping(
      worklistValues.int2ToInt3,
      "Int2ToInt3",
      intPlateState
    );
    const int1ToDest = getIntToDest(
      worklistValues.int1ToDest,
      "Int1ToDest",
      intPlateState,
      destPlateState
    );
    const int2ToDest = getIntToDest(
      worklistValues.int2ToDest,
      "Int2ToDest",
      intPlateState,
      destPlateState
    );
    const int3ToDest = getIntToDest(
      worklistValues.int3ToDest,
      "Int3ToDest",
      intPlateState,
      destPlateState
    );
    const int1StampTopLeft = getIntStampTopLeft(
      worklistValues.int1StampTopLeft,
      "Int1StampTopLeft"
    );
    const int2StampTopLeft = getIntStampTopLeft(
      worklistValues.int2StampTopLeft,
      "Int2StampTopLeft"
    );
    const int3StampTopLeft = getIntStampTopLeft(
      worklistValues.int3StampTopLeft,
      "Int3StampTopLeft"
    );
    const stampTopLeftTransfers = getStampTopLeftTransfers(
      worklistValues.stampTopLeftTransfers
    );

    const referenceStampShape = [
      {
        task: "ReferenceStampShape",
        sourceWellID:
          poolingNormalizationToolInfo.worklistValues.referenceStampShape.join(
            ","
          ),
      },
    ];

    const seedingPriority = buildSeedingPriorityWorklist(
      methodSettings.groupedSeedingPriority,
      methodSettings.seedingPriority_12_24_Well,
      methodSettings.seedingPriority_96_384_Well
    );

    const worklistMethodSettings = buildMethodSettingsWorklist(
      poolingNormalizationToolInternalState,
      false
    );

    const worklist = [
      ...harvestWells,
      ...int1ToInt2,
      ...int1ToInt3,
      ...int2ToInt3,
      ...int1ToDest,
      ...int2ToDest,
      ...int3ToDest,
      ...int1StampTopLeft,
      ...int2StampTopLeft,
      ...int3StampTopLeft,
      ...stampTopLeftTransfers,
      ...referenceStampShape,
      ...seedingPriority,
      ...worklistMethodSettings,
    ];

    const template = {
      worklistToolTemplateId,
      templateName,
      methodId: 111,
      sourcePlateInfo: getSourcePlateInfo(poolingNormalizationToolInfo),
      intPlateInfo: getIntPlateInfo(poolingNormalizationToolInfo),
      destPlateInfo: getDestPlateInfo(poolingNormalizationToolInfo),
      deadPlateInfo: getDeadPlateInfo(poolingNormalizationToolInfo),
      worklistToolTemplateContentsInput: worklist,
    };

    return template;
    // overwriteExistingWorklistToolTemplate({
    //   variables: {
    //     input: template,
    //   },
    // });
  };

  const handleTemplateClose = (
    loadTemplate: boolean,
    templateInfo: WorklistToolTemplate | undefined
  ) => {
    setTemplateDialogOpen(false);
    if (!loadTemplate) return;

    const harvestWells = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "HarvestWells"
      ) ?? []
    );
    const int1ToInt2 = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int1ToInt2"
      ) ?? []
    );
    const int1ToInt3 = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int1ToInt3"
      ) ?? []
    );
    const int2ToInt3 = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int2ToInt3"
      ) ?? []
    );

    const int1ToDest = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int1ToDest"
      ) ?? []
    );

    const int2ToDest = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int2ToDest"
      ) ?? []
    );

    const int3ToDest = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int3ToDest"
      ) ?? []
    );

    const stampTopLeftTransfers = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "StampTopLeftTransfers"
      ) ?? []
    );

    const referenceStampShape =
      templateInfo?.worklistToolTemplateContents
        ?.find((e) => e?.task === "ReferenceStampShape")
        ?.sourceWellId?.split(",") ?? [];

    const int1StampTopLeft = parseStampTopLeftValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int1StampTopLeft"
      )
    );

    const int2StampTopLeft = parseStampTopLeftValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int2StampTopLeft"
      )
    );
    const int3StampTopLeft = parseStampTopLeftValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "Int3StampTopLeft"
      )
    );

    const templateMethodSettings = parseMethodSettingsTemplate(
      templateInfo?.worklistToolTemplateContents
    );

    const sourcePlates = templateInfo?.worklistToolTemplatePlates
      ?.filter((e) => e?.plateType === "Source")
      .map((item) => {
        const rows = [...new Array(item?.numberOfRows)].map(
          (item, index) => index + 1
        );
        const cols = [...new Array(item?.numberOfColumns)].map(
          (item, index) => index + 1
        );
        return {
          plateBarcode: "",
          labwareTypeCode: item?.labwareTypeCode,
          operatingVol: item?.operatingVol ?? 0,
          rows,
          cols,
          wellInfo: fillSourcePlateWithColors(rows, cols),
        };
      });
    const intPlates = templateInfo?.worklistToolTemplatePlates
      ?.filter((e) => e?.plateType === "Int")
      .map((item) => {
        return {
          plateBarcode: "",
          labwareTypeCode: item?.labwareTypeCode,
          operatingVol: item?.operatingVol ?? 0,
          resuspensionVol: item?.resuspensionVol ?? 0,
          rows: [...new Array(item?.numberOfRows)].map(
            (item, index) => index + 1
          ),
          cols: [...new Array(item?.numberOfColumns)].map(
            (item, index) => index + 1
          ),
        };
      });
    const destPlates: DestPlateInfoState[] | undefined =
      templateInfo?.worklistToolTemplatePlates
        ?.filter((e) => e?.plateType === "Dest")
        .map((item) => {
          return {
            index: item!.plateIndex,
            plateBarcode: "",
            labwareTypeCode: item?.labwareTypeCode ?? "",
            operatingVol: item?.operatingVol ?? 0,
            preprocess: item?.topup ?? false,
            topup: item?.preprocess ?? false,
            minOperatingVol: 0,
            startingVol: item?.startingVol ?? 0,
            rows: [...new Array(item?.numberOfRows)].map(
              (item, index) => index + 1
            ),
            cols: [...new Array(item?.numberOfColumns)].map(
              (item, index) => index + 1
            ),
          };
        });

    const deadPlateType =
      templateInfo?.worklistToolTemplatePlates
        ?.filter((e) => e?.plateType === "Dead")
        .map((item) => item?.labwareTypeCode)[0] ?? "";

    dispatch(
      PoolingNormalizationToolActions.UPLOAD_PLATES_FROM_TEMPLATE({
        sourcePlates,
        intPlates,
        destPlates,
        deadPlateType,
      })
    );

    setTimeout(() => {
      dispatch(
        PoolingNormalizationToolActions.UPLOAD_WORKLIST_VALUES_FROM_TEMPLATE({
          harvestWells,
          int1ToInt2,
          int1ToInt3,
          int2ToInt3,
          int1ToDest,
          int2ToDest,
          int3ToDest,
          stampTopLeftTransfers,
          referenceStampShape,
          int1StampTopLeft,
          int2StampTopLeft,
          int3StampTopLeft,
          // sourcePlates,
          // intPlates,
          // destPlates,
          // deadPlateType,
        })
      );
    }, 1000);

    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
      payload: {
        methodSettings: templateMethodSettings,
      },
    });

    if (
      containsCrossContamination({
        int1StampTopLeft,
        int2StampTopLeft,
        int3StampTopLeft,
      } as WorklistValuesState)
    ) {
      Alert({
        type: AlertType.WARNING,
        message:
          "This template contains a cross contamination because it has stamps from multiple intermediate wells",
      });
    }
  };

  return (
    <React.Fragment>
      <StyledToolbarSection>
        <Tooltip
          onOpen={checkForWarnings}
          title={
            sourcePlateAlreadyReserved ? (
              "Source Plate has already been reserved. Please delete the existing reservation"
            ) : (
              <WorklistToolWarnings
                requiredWarnings={requiredWarnings}
                optionalWarnings={optionalWarnings}
              />
            )
          }
          placement="right"
        >
          <div>
            <StyledIconButton
              onClick={handleGenerateWorklistClick}
              disabled={sourcePlateAlreadyReserved}
            >
              <GetAppTwoToneIcon />
            </StyledIconButton>
          </div>
        </Tooltip>
        <StyledIconButton onClick={() => setUploadWorklistOpen(true)}>
          <Tooltip title="Upload Worklist" placement="right">
            <UploadOutlinedIcon />
          </Tooltip>
        </StyledIconButton>
        <StyledIconButton onClick={() => setTemplateDialogOpen(true)}>
          <Tooltip title="Open Template" placement="right">
            <FolderOpenIcon />
          </Tooltip>
        </StyledIconButton>
        <StyledIconButton onClick={() => setTwoOptionDialogOpen(true)}>
          <Tooltip title="Save Template" placement="right">
            <SaveIcon />
          </Tooltip>
        </StyledIconButton>
        <StyledIconButton
          disabled={
            methodReservation?.existingMethodReservation
              ? !(methodReservation?.existingMethodReservation?.length > 0)
              : true
          }
          onClick={() => handleDeleteMethodReservationClick()}
        >
          <Tooltip title="Delete Existing Reservation" placement="right">
            <DeleteForeverIcon />
          </Tooltip>
        </StyledIconButton>
      </StyledToolbarSection>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

      <WarningDialog
        title={"Warning"}
        message={optionalWarnings}
        isOpen={warningDialogOpen}
        handleClose={(continueAction) =>
          handleWarningDialogClose(continueAction)
        }
      />

      <UploadWorklist
        isOpen={uploadWorklistOpen}
        handleClose={(uploadWorklist, worklistRows) =>
          handleUploadWorklistClose(uploadWorklist, worklistRows)
        }
      />

      <TwoOptionDialog
        isOpen={twoOptionDialogOpen}
        handleClose={(rightButton) => handleTwoOptionDialogClose(rightButton)}
        title={"Do you want to overwrite an existing template?"}
        leftButtonText="No"
        rightButtonText="Yes"
      />

      <ConfirmDialog leftButtonText="No" rightButtonText="Yes" />

      <NewTemplateNameDialog
        isOpen={saveTemplateDialogOpen}
        handleClose={(saveTemplate, templateName) =>
          handleSaveTemplateClose(saveTemplate, templateName)
        }
      />

      <ViewWorklistTemplate
        isOpen={templateDialogOpen}
        handleClose={(loadTemplate, templateInfo) =>
          overwriteTemplateMode
            ? handleOverwriteTemplateClose(loadTemplate, templateInfo)
            : handleTemplateClose(loadTemplate, templateInfo)
        }
        overwriteTemplateMode={overwriteTemplateMode}
        methodId={Methods.PoolingNormalization}
        // Update this date whenever outputted worklist is changed
        dateWorklistLastUpdated={new Date("06/24/2024 05:00:00 PM")}
      />
    </React.Fragment>
  );
};
